<template>
  <div class="invoice-form">
    <v-form ref="form" class="invoice-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="5">
          <v-row>
            <v-col cols="12">
              <label class="text-field-label">File Input</label>
              <v-file-input
                v-model="form.file"
                :loading="form.$busy"
                :disabled="form.$busy"
                flat
                solo
                clearable
                show-close="false"
                :error-messages="
                  isFileTooLarge
                    ? 'The file must be less than 4MB'
                    : form.$getError('file_input')
                "
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Supplier</label>
              <SelectSupplier
                flat
                solo
                required
                hide-details="auto"
                v-model="form.supplier_id"
                :pre-select="!isUpdate"
                :error-messages="form.$getError('supplier_id')"
                :loading="form.$busy"
                :disabled="form.$busy"
              />
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Invoice #</label>
              <v-text-field
                flat
                solo
                required
                type="number"
                min="0"
                placeholder="Amount"
                v-model="form.invoice_number"
                :loading="form.$busy"
                :disabled="form.$busy"
                :error-messages="form.$getError('invoice_number')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Account</label>
              <SelectAccount
                flat
                solo
                required
                hide-details="auto"
                v-model="form.chart_of_account_id"
                :pre-select="!isUpdate"
                :error-messages="form.$getError('chart_of_account_id')"
                :loading="form.$busy"
                :disabled="form.$busy"
              />
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Due Date</label>
              <DatetimePicker
                v-model="form.due_date"
                flat
                solo
                required
                type="date"
                class="mt-2"
                placeholder="Due Date"
                hide-details="auto"
                :error-messages="form.$getError('due_date')"
                :loading="form.$busy"
                :disabled="form.$busy"
              />
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Amount</label>
              <v-text-field
                flat
                solo
                required
                type="number"
                min="0"
                placeholder="Amount"
                v-model="form.amount"
                :loading="form.$busy"
                :disabled="form.$busy"
                :error-messages="form.$getError('amount')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Description</label>
              <v-textarea
                flat
                solo
                required
                hide-details="auto"
                placeholder="Description"
                v-model="form.description"
                :error-messages="form.$getError('description')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="7">
          <PreviewPDF :file="form.file" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <div class="mt-4">
            <v-btn
              v-if="hasPermissions"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Perk Form
 * ==================================================================================
 **/
import { mapActions, mapState } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { mdiPlus } from '@mdi/js'
import DatetimePicker from '@/components/fields/DatetimePicker.vue'
import SelectSupplier from '@/components/fields/SelectSupplier.vue'
import SelectAccount from '@/components/fields/SelectAccount.vue'
import PreviewPDF from '@/views/Home/Maintenance/Requests/components/PreviewPDF.vue'

export default {
  components: {
    PreviewPDF,
    SelectAccount,
    SelectSupplier,
    DatetimePicker,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    invoice: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        supplier_id: '',
        maintenance_id: '',
        chart_of_account_id: '',
        amount: '',
        file: null,
        invoice_number: '',
        description: '',
        due_date: '',
      }),
      selected: [],
      options: {},
      loading: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      createdInvoice: (state) => state.invoice.invoiceDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Invoice Information' : 'Create Invoice'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateInvoice : this.createInvoice
    },

    hasPermissions() {
      return validatePermissions([PERMISSION.INVOICES_CREATE], this.permissions)
    },

    isUpdate() {
      return !!this.invoice
    },

    isFileTooLarge() {
      if (this.form.file) {
        return this.form.file.size > 4 * 1024 * 1024
      }
      return false
    },
  },

  watch: {
    invoice() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createInvoice: 'invoice/createInvoice',
      updateInvoice: 'invoice/updateInvoice',
    }),

    initForm() {
      if (this.invoice) {
        this.form.maintenance_id = this.invoice.maintenance_id
        this.form.amount = this.invoice.amount
        this.form.supplier_id = this.invoice.supplier_id
        this.form.chart_of_account_id = this.invoice.chart_of_account_id
        this.form.description = this.invoice.description
        this.form.invoice_number = this.invoice.invoice_number
        this.form.due_date = this.invoice.due_date
        this.form.file = this.invoice.file
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      const formData = this.getFormData()
      const id = this.invoice.id
      await this.submitApi({ formData, id })
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Invoice details successfully updated!')
          } else {
            this.showSnackbar('Invoice successfully created!')
            this.$router.push({
              name: 'maintenance.invoices',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      let file = form.file

      if (!(file instanceof Blob) && !(file instanceof File)) {
        delete form.file
      }

      const formData = new FormData()
      for (var field in form) {
        formData.append(field, form[field])
      }

      return formData
    },
  },
}
</script>
<style lang="scss">
.invoice-form {
  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
